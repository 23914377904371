.simple-text-input {
  height: 54px;
  width: 100%;
  margin-bottom: 14px;
  padding-left: 14px;
  background-color: #e9e7e2 !important;
  border: 1px solid black;
  font-size: 18px;
}

.simple-text-input::placeholder {
  background-color: #e9e7e2;
  color: color(textDark);
  font-size: 18px;
}

.text-input-transparent {
  height: 40px;
  width: 100%;
  padding-left: 14px;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  font-size: 18px;
}

.text-input-transparent::placeholder {
  background-color: transparent;
  color: black;
  font-size: 18px;
}

.text-input-small {
  height: 30px;
  width: 220px;
  background-color: transparent;
  padding-left: 10px;
  border: 1px solid black;
  color: black;
  font-size: 16px;
}

.text-input-small::placeholder {
  background-color: transparent;
  color: black;
  font-size: 16px;
}
.standard-form {
  border: 1px solid black;
  width: 480px;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.select-text {
  padding-left: 12px;
}
