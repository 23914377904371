/* 'Roboto Condensed' 'Architects Daughter' 'Source Sans Pro' */

@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css?family=Josefin+Slab&display=swap");

html {
  padding: 0;
  margin: 0;
  height: 100vh;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background: #f5f5f5;
  min-height: 100vh;
}
.App,
#root {
  min-height: 100vh;
}
h1 {
  font-family: "Source Sans Pro", sans-serif;
}
h2 {
  font-size: 1.6em;
  font-family: "Josefin Slab";
}
h3 {
  font-size: 14px;
  margin: 2vmin;
  font-family: "Josefin Slab";
}
p {
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 1.4em;
}
a {
  /* font-family: "Josefin Slab"; */
  text-decoration: none;
  color: rgb(0, 0, 0);
  outline: none;
}
/*Pages*/

.hidden {
  display: none;
}

.page-frame {
  padding: 10px;
  padding-bottom: 60px;
  min-height: calc(100vh - 70px - 40px - 100px);
}

@media only screen and (max-width: 500px) {
  .page-frame {
    padding-bottom: 20px;
  }
}

.gallery-holder {
  padding: 0px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.underlined {
  text-decoration: underline;
}

.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextField-without-border-radius .MuiInputBase-formControl {
  border-radius: 0;
}

/* general */
.mb-10 {
  margin-bottom: 10px;
}
