@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css?family=Josefin+Slab&display=swap);
/* 'Roboto Condensed' 'Architects Daughter' 'Source Sans Pro' */

html {
  padding: 0;
  margin: 0;
  height: 100vh;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background: #f5f5f5;
  min-height: 100vh;
}
.App,
#root {
  min-height: 100vh;
}
h1 {
  font-family: "Source Sans Pro", sans-serif;
}
h2 {
  font-size: 1.6em;
  font-family: "Josefin Slab";
}
h3 {
  font-size: 14px;
  margin: 2vmin;
  font-family: "Josefin Slab";
}
p {
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 1.4em;
}
a {
  /* font-family: "Josefin Slab"; */
  text-decoration: none;
  color: rgb(0, 0, 0);
  outline: none;
}
/*Pages*/

.hidden {
  display: none;
}

.page-frame {
  padding: 10px;
  padding-bottom: 60px;
  min-height: calc(100vh - 70px - 40px - 100px);
}

@media only screen and (max-width: 500px) {
  .page-frame {
    padding-bottom: 20px;
  }
}

.gallery-holder {
  padding: 0px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.underlined {
  text-decoration: underline;
}

.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextField-without-border-radius .MuiInputBase-formControl {
  border-radius: 0;
}

/* general */
.mb-10 {
  margin-bottom: 10px;
}

.element-slider-show {
  opacity: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
  left: 0;
  transition: 2.3s;
  max-height: 100%;
  overflow: hidden;
}
.element-slider-hidden-top {
  opacity: 0;
  position: absolute;
  top: 40%;
  right: 0;
  transition: 0;
  max-height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.element-slider-hidden-left {
  opacity: 0;
  position: absolute;
  left: 200;
  transition: 0.3s;
}
.slide-text {
  max-height: 100%;
  overflow: hidden;
  font-size: 3.5em;
  color: white;
  text-shadow: 3px 3px 3px black;
}
.slide-button {
  width: 500px;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
  border: 5px solid white;
}
.slide-button:hover {
  transition: 0.3s;
  border-radius: 15px;
  /* background: rgba(200, 200, 200, 0.5); */
}

@media only screen and (max-width: 500px) {
  .element-slider-show {
    top: 40%;
  }
  .element-slider-hidden-top {
    top: 25%;
  }
  .slide-text {
    font-size: 2em;
  }
  .slide-button {
    width: 300px;
    max-width: 80%;
  }
}

.exhibitions-parent {
  display: block;
  text-align: center;
}
.art-show-holder {
  margin: 0 auto;
}
.art-show-card {
  display: inline-block;
  margin: 50px auto 0px auto;
  max-height: 100vh;
  max-width: 95%;
  border: 1px solid black;
  overflow: hidden;
}
.art-show-card img {
  /* display: block; */
  object-fit: contain;
  max-height: calc(100vh - 80px);
}
.art-show-title {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto Condensed";
  font-size: 1.6em;
}
.exhibitions-list {
  text-align: left;
  width: 95%;
  max-width: 100%;
  margin: 50px auto 100px auto;
  padding: 20px;
  border: 1px solid black;
}
.exhibitions-list p {
  margin-bottom: 10px;
}

/*new 2020*/
.exhibitions-container {
  padding: 20px 40px 0px 40px;
}
.exhibition-viewer {
  /* height: 80vh; */
  /* min-height: 400px; */
  height: auto;
  width: 100%;
  border: 1px solid black;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-position: center;
  background-size: cover;
  margin-bottom: 60px;
  position: relative;
  background-color: white;
}
.exhibition-background {
  position: absolute;
  z-index: -1;
  opacity: 0.75;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.exhibition-description-frame {
  background: #fafafa;
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1px solid black;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.exhibition-images-frame {
  /* height: 40%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.side-image-frame {
  height: 100%;
  width: 30%;
  border: 1px solid black;
  overflow: hidden;
}
.side-image {
  height: 100%;
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  object-fit: cover;
  object-position: center;
  display: block;
  cursor: pointer;
}
.expanded-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.image-exit-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
  z-index: 5;
  cursor: pointer;
}
.image-exit-button:hover {
  text-shadow: 0px 0px 5px white;
}
.exhibition-subtitle {
  font-weight: bold;
}
.exhibition-desc {
  text-align: left;
}
.exhibition-copy {
  font-size: 22px;
}
.exhibition-link {
  margin-top: 10px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  text-decoration: underline;
}
.exhibition-link:hover {
  color: blue;
}
@media screen and (max-width: 1000px) {
  .exhibition-viewer {
    flex-direction: column;
    justify-content: space-between;
    /* height: 60vh; */
  }
}
@media screen and (max-width: 700px) {
  .exhibition-viewer {
    padding: 10px;
  }
  .exhibition-title {
    font-size: 18px;
  }
  .exhibition-copy {
    font-size: 14px;
  }
  .exhibition-images-frame {
    height: 70%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .side-image-frame {
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .side-image-frame:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 400px) {
  .exhibition-title {
    font-size: 16px;
  }
  .exhibition-copy {
    font-size: 11px;
  }
}

.bun-story {
  position: fixed;
  background: black;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.bun-story-page {
  height: 100vh;
  position: fixed;
  top: 0;
  overflow: hidden;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-relative {
  position: relative;
}
.bun-story-img {
  height: 100%;
  transition: ease-in-out 0.7s;
  margin: 0 auto;
  object-fit: cover;
  display: block;
  pointer-events: none;
}
.scroll-indicator {
  position: fixed;
  z-index: 2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  color: white;
  top: 40vh;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  transition: 1s;
}
.scroll-indicator-hidden {
  opacity: 0;
  transform: translateY(-70%);
}
@media screen and (max-width: 700px) {
  .scroll-indicator {
    transform: translateX(15%);
    font-size: 18px;
  }
  .scroll-indicator-hidden {
    opacity: 0;
    transform: translate(15%, -70%);
  }
}
.scroll-indicator-down-arrow {
  position: relative;
  margin-top: 10px;
  fill: white;
  height: 40px;
  width: 40px;
  transition: ease-in-out 2s;
}
.arrow-up {
  transform: translateY(-10%);
}
.arrow-down {
  transform: translateY(10%);
}
.color-flash {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 2;
  transition: 2s;
}
.color-flash-inactive {
  opacity: 0;
}
.camera-flash {
  position: fixed;
  height: 100%;
  width: 5%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
  transition: 0.2s;
  opacity: 0.8;
  box-shadow: 0px -100px 50vw 50vw rgba(255, 255, 255, 0.3);
  right: 0;
}
.camera-flash-inactive {
  opacity: 0;
  box-shadow: 0px 0px 0px 0vw rgba(255, 255, 255, 0.3);
}
.teaTime-pos-1 {
  transform: scale(1.3) translateX(0vw) translateY(-10vh);
}
.teaTime-pos-2 {
  transform: scale(2.5) translateX(-8%) translateY(-30%);
}
.teaTime-pos-3 {
  transform: scale(1) translateX(-0%) translateY(-0%);
}
.teaTime-pos-4 {
  transform: scale(1.8) translateX(-30%) translateY(-20%);
}
.teaTime-pos-5 {
  transform: scale(1.8) translateX(-30%) translateY(20%);
}
.teaTime-pos-6 {
  transform: scale(1.5) translateX(-0%) translateY(-0%);
}
.teaTime-pos-7 {
  transition: 3s;
  transform: scale(2.6) translateX(24%) translateY(-14%);
}
.teaTime-pos-8 {
  transition: 3s;
  opacity: 0;
  transform: scale(2.8) translateX(0%) translateY(-44%);
}
.museum {
  transition: 1s;
  z-index: 1;
}
.museum-pos-1 {
  opacity: 0;
  transform: translateY(-20%);
}
.museum-pos-2 {
  transition: 3s;
  transition-delay: 1500ms;
  opacity: 1;
  transform: scale(1.5) translate(10%, -10%);
}
.museum-pos-3 {
  transform: scale(1.1);
}
.museum-pos-4 {
  transform: scale(1.5);
  transform: translateX(-20%);
}
.museum-pos-5 {
  transform: scale(1.9) translate(-11%, -25%);
}
.museum-pos-6 {
  transform: scale(2.9) translate(-14%, -28%);
}
.museum-pos-7 {
  transform: scale(1) translate(-0%, -0%);
}
.museum-pos-8 {
  opacity: 0;
}
.harrysLR {
  z-index: 1;
}
.harrys-pos-1 {
  transition-delay: 500ms;
  opacity: 0;
}
.harrys-pos-2 {
  transition-delay: 500ms;
  opacity: 1;
  transform: scale(1.8);
}
.harrys-pos-3 {
  /* couch */
  transform: scale(1.2) translate(-14%, -10%);
}
.harrys-pos-4 {
  /* clues */
  transition-delay: 500ms;
  transform: scale(1.2);
}
.harrys-pos-5 {
  transform: scale(1.7) translate(-20%, 20%);
}
.harrys-pos-6 {
  transform: scale(1.7) translate(20%, 23%);
}
.harrys-pos-7 {
  transform: scale(1.7) translate(10%, -35%);
}
.harrys-pos-8 {
  transform: scale(1.9) translate(6%, -15%);
}
.harrys-pos-9 {
  transform: scale(2.1) translate(2%, -30%);
  opacity: 0;
}
.directors-pos-1 {
  opacity: 0;
}
.directors-pos-2 {
  transform: scale(1.2);
}
.directors-pos-3 {
  /* floral */
  transform: scale(1.6) translate(-8%, -30%);
}
.directors-pos-4 {
  /* below bookcase */
  transform: scale(1.5) translate(-16%, 10%);
}
.directors-pos-5 {
  /* bookcase */
  transition: ease-out 3s;
  transform: scale(1.5) translate(-16%, 15%);
}
.directors-pos-6 {
  /*desk*/
  transform: scale(2) translate(18%, -14%);
}
.directors-pos-7 {
  /*chair*/
  transform: scale(2.2) translate(-8.5%, -9%);
}
.directors-pos-8 {
  /*daydream light*/
  transition-delay: 1000ms;
  transition: ease-out 6s;
  transform: scale(1.8) translate(28%, 2%);
}
.directors-pos-9 {
  /*starkly awoken*/
  transition: ease 0.3s;
  transform: scale(1.5) translate(-14%, -16%);
}
.directors-pos-10 {
  /*red bloom*/
  transform: scale(2.7) translate(-10%, -45%);
}
.directors-pos-11 {
  transform: scale(2.7) translate(-100%, -15%);
  opacity: 0;
}
.italian-pos-1 {
  opacity: 0;
  transition: ease 5s;
  transform: scale(2);
}
.italian-pos-2 {
  transition: ease 5s;
  transform: scale(1.5);
}
.italian-pos-3 {
  /*spot the head*/
  transform: scale(1.5) translate(-2%, -25%);
}
.italian-pos-4 {
  /*camera flash right*/
  transform: scale(1.1) translate(-15%, -0%);
}
.italian-pos-5 {
  /*furthest table*/
  transform: scale(2.6) translate(30%, -18%);
}
.italian-pos-6 {
  transform: scale(1);
}
.italian-pos-7 {
  /*cern finds the head*/
  transform: scale(1.5) translate(14%, -25%);
}
.italian-pos-8 {
  transform: scale(1);
}
.italian-pos-9 {
  /* sharp fork */
  transform: scale(2.6) translate(30%, -18%);
}
.italian-pos-10 {
  transform: scale(1.6) translate(-30%, -15%);
  opacity: 0;
}
.italian-pos-11 {
  opacity: 0;
  transform: translate(-30%, -25%);
}
.lydias-pos-1 {
  opacity: 0;
  transform: translate(-55%, -5%);
}
.lydias-pos-2 {
  transform: scale(1.5) translate(-0%, -0%);
}
.lydias-pos-3 {
  /*approach table*/
  transform: scale(1.2) translate(10%, 10%);
}
.lydias-pos-4 {
  /*clock ticks*/
  transform: scale(2.5) translate(-7%, -7%);
}
.lydias-pos-5 {
  transform: translate(-0%, -0%);
}
.lydias-pos-6 {
  /*approach table*/
  transform: scale(1.2) translate(10%, 10%);
}
.lydias-pos-7 {
  /*hearth beckons*/
  transition: ease 4s;
  transform: scale(1.9) translate(2%, 16%);
}
.lydias-pos-8 {
  /*eating*/
  transform: scale(1.9) translate(17%, 16%);
}
.lydias-pos-9 {
  /*center of table*/
  transform: scale(2.8) translate(22%, 16%);
}
.lydias-pos-10 {
  /*lunged forward*/
  transform: scale(1.8) translate(22%, 16%);
}
.lydias-pos-11 {
  /*stand up*/
  transform: scale(1.1) translate(0%, 0%);
}
.lydias-pos-12 {
  /*table cracked*/
  transform: scale(2.3) translate(22%, 16%);
}
.lydias-pos-13 {
  transform: translate(-0%, -0%);
}
.lydias-pos-14 {
  opacity: 0;
  transform: translate(-35%, -5%);
}
.porch-pos-1 {
  opacity: 0;
  transform: scale(1.8);
}
.porch-pos-2 {
  transform: scale(1.8);
}
.porch-pos-3 {
  /*turn startled*/
  transition-duration: 0.3s;
  transform: scale(1.8) translateX(-10%);
}
.porch-pos-4 {
  transform: translate(-20%);
}
.porch-pos-5 {
  transform: scale(1.2) translateX(-50%);
  opacity: 0;
}
.study-pos-1 {
  opacity: 0;
  transform: scale(1.2) translateX(50%);
}
.study-pos-2 {
  transform: scale(1.3);
}
.study-pos-3 {
  /*spacious chairs*/
  transform: scale(1) translateX(20%);
}
.study-pos-4 {
  /*professor stab*/
  transform: scale(2) translateX(-10%);
}
.study-pos-5 {
  transform: scale(1);
}
.study-pos-6 {
  transform: translate(-25%);
}
.study-pos-7 {
  transform: translate(0);
}
.story-image-small {
  transition: 15s;
  transform: scale(0.3);
}
.story-image-small-2 {
  transition: 15s;
  transform: scale(0.1);
  opacity: 0;
}
.english-lr-pos-1 {
  opacity: 0;
  transform: translateY(-100%);
}
.front-hall-pos-1 {
  opacity: 0;
  transform: translateX(-25%) translateY(-100%);
}
.tiled-hallway-pos-1 {
  opacity: 0;
  transform: translateX(-50%) translateY(-100%);
}
.enclosed-porch-pos-1 {
  opacity: 0;
  transform: translateX(50%) translateY(-100%);
}
.teaTime-end-grid-1 {
  transition-delay: 100;
  transform: scale(0.3) translate(-165%, -90%);
}
.museum-end-grid-1 {
  transition-delay: 200;
  transform: scale(0.4) translate(150%, 50%);
}
.harrysLR-end-grid-1 {
  transition-delay: 300;
  transform: scale(0.5) translate(150%, -150%);
}
.directors-end-grid-1 {
  transition-delay: 400;
  transform: scale(0.3) translate(-150%, -25%);
}
.italian-end-grid-1 {
  transition-delay: 500;
  transform: scale(0.2) translate(-60%, -10%);
}
.lydias-end-grid-1 {
  transition-delay: 600;
  transform: scale(0.3) translate(-170%, -134%);
}
.porch-end-grid-1 {
  transition-delay: 700;
  transform: scale(0.3) translate(109%, 105%);
}
.study-end-grid-1 {
  transition-delay: 800;
  transform: scale(0.3) translate(-170%, 99%);
}
.english-lr-pos-1 {
  opacity: 0;
}
.front-hall-pos-1 {
  opacity: 0;
}
.tiled-hallway-pos-1 {
  opacity: 0;
}
.enclosed-porch-pos-1 {
  opacity: 0;
}
.english-lr-end-grid-1 {
  transition-delay: 900;
  transform: scale(0.3) translateY(50%);
}
.front-hall-end-grid-1 {
  transition-delay: 1000;
  transform: scale(0.3) translateX(-180%) translateY(110%);
}
.tiled-hallway-end-grid-1 {
  transition-delay: 1100;
  transform: scale(0.3) translateX(-5%) translateY(-110%);
}
.enclosed-porch-end-grid-1 {
  transition-delay: 1200;
  transform: scale(0.3) translateX(90%) translateY(-50%);
}

.teaTime-end-grid-2,
.museum-end-grid-2,
.harrysLR-end-grid-2,
.directors-end-grid-2,
.italian-end-grid-2,
.lydias-end-grid-2,
.porch-end-grid-2,
.study-end-grid-2,
.english-lr-end-grid-2,
.front-hall-end-grid-2,
.tiled-hallway-end-grid-2,
.enclosed-porch-end-grid-2 {
  transform: translate(-0%, -0%);
  opacity: 0;
}

.bun-story-text-container {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  z-index: 2;
}
.story-title {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 54px;
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 50px;
  text-shadow: 1px 1px 10px black;
}
.bun-story-text {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 24px;
  width: 40%;
  text-shadow: 2px 2px 5px black;
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  border: 1px solid white;
}
.bun-text-index {
  font-size: 10px;
  margin-top: 5px;
  text-align: right;
}
.story-credits-container {
  align-self: center;
  margin-bottom: 50vh;
}
.story-credits {
  transition: 1s;
  color: white !important;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  border: 1px solid white;
  align-self: center;
  padding: 20px;
  background-color: black;
}
.story-credits-text {
  color: white !important;
  text-align: center;
  margin-bottom: 10px;
  transition: 1s;
}
.story-credits-title {
  transition: 1s;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
}
.credits-text-1 {
  transition-delay: 0.3s;
}
.credits-text-2 {
  transition-delay: 0.5s;
}
.credits-text-3 {
  transition-delay: 0.7s;
}
.credits-text-4 {
  transition-delay: 1s;
}
.transparent-above {
  opacity: 0;
  transform: translateY(-20px);
}
@media screen and (max-width: 700px) {
  .bun-story-text {
    padding: 10px;
    font-size: 14px;
    width: 50%;
  }
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-height: 700px) {
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .bun-story-text {
    padding: 10px;
    font-size: 12px;
    width: 50%;
  }
}
.transition-1-sec {
  transition: ease-in-out 1s;
}
.transparent {
  opacity: 0;
  pointer-events: none;
}
.text-show {
  opacity: 1;
}
.text-1 {
  margin-top: 10vh;
}
.text-2-class-1 {
  transform: translateX(-50%);
}
.text-4 {
  margin-bottom: 80vh;
}
.text-6 {
  margin-bottom: 80vh;
}
.text-7 {
  margin-bottom: 60vh;
}
.text-8 {
  margin-bottom: 60vh;
}
.text-13 {
  margin-bottom: 30vh;
}
.text-18 {
  margin-bottom: 40vh;
}
.text-19 {
  margin-bottom: 30vh;
}
.text-20 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30vh;
}
.text-26 {
  margin-bottom: 40vh;
}
.text-27 {
  transition: 0.3s;
}
.text-27-class-1 {
  transform: skew(10deg, 0deg);
}
.text-104 {
  margin-bottom: 50vh;
}

.text-3,
.text-4,
.text-7,
.text-9,
.text-13,
.text-16,
.text-17,
.text-26,
.text-32,
.text-33,
.text-34,
.text-35,
.text-39,
.text-41,
.text-43,
.text-45,
.text-50,
.text-52,
.text-54,
.text-56,
.text-58,
.text-67,
.text-65,
.text-68,
.text-69,
.text-70,
.text-71,
.text-72,
.text-75,
.text-76,
.text-77,
.text-79,
.text-85,
.text-87,
.text-88,
.text-89,
.text-90,
.text-98,
.text-99,
.text-100 {
  align-self: flex-end;
}

.text-4-class-1,
.text-17-class-1,
.text-32-class-1,
.text-35-class-1,
.text-39-class-1,
.text-41-class-1,
.text-43-class-1,
.text-45-class-1,
.text-50-class-1,
.text-52-class-1,
.text-54-class-1,
.text-56-class-1,
.text-58-class-1,
.text-85-class-1,
.text-87-class-1,
.text-89-class-1 {
  transform: translate(150%);
}

.text-10-class-1,
.text-37-class-1,
.text-40-class-1,
.text-42-class-1,
.text-44-class-1,
.text-46-class-1,
.text-48-class-1,
.text-51-class-1,
.text-53-class-1,
.text-55-class-1,
.text-57-class-1,
.text-59-class-1,
.text-63-class-1,
.text-78-class-1,
.text-82-class-1,
.text-84-class-1,
.text-86-class-1,
.text-92-class-1,
.text-94-class-1 {
  transform: translate(-150%);
}

.text-5,
.text-6,
.text-8,
.text-27,
.text-31,
.text-64,
.text-73,
.text-74,
.text-95,
.text-96,
.text-101 {
  width: 100%;
  align-self: center;
}
.text-64 {
  margin-bottom: 30vh;
  text-align: center;
}

.text-5-class-1,
.text-7-class-1,
.text-95-class-1 {
  transform: skewX(10deg);
}
.text-6-class-1,
.text-96-class-1 {
  transform: skewX(-10deg);
}

.auth-page-body {
  min-height: calc(100vh - 72px - 38px - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-frame {
  width: 100%;
  max-width: 448px;
  /* height: 500px; */
  /* margin-bottom: 40px; */
  background-color: #e9e7e2;
  padding: 38px;
  padding-bottom: 70px;
  margin: 0 auto;
  /* height: 100%; */
}
@media (max-width: 460px) {
  .auth-frame {
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 100%;
    width: 100%;
  }
}

.auth-brand-header,
.auth-header {
  display: block;
  text-align: center;
}
.auth-brand-header {
  color: Black;
  margin-bottom: 14px;
  font-size: 24px;
  font-family: "Josefin Slab", "sans";
}
.auth-header {
  margin-bottom: 34px;
  font-size: 18px;
  font-family: "Josefin Slab", "sans";
}
.auth-form {
  width: 100%;
}
.log-in-with-google {
  height: 42px;
  border: 1px solid rgb(66, 133, 244);
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
}
.google-svg {
  width: 45px;
}
.google-login-text {
  font-size: 18px;
  font-family: "Josefin Slab", "sans";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgb(66, 133, 244);
  width: 100%;
}
.or-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.forgot-password {
  margin-bottom: 35px;
  font-size: 18px;
}
.auth-link {
  font-family: "Roboto";
}
.auth-bottom-links {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-frame a:hover {
  color: blue;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.simple-text-input {
  height: 54px;
  width: 100%;
  margin-bottom: 14px;
  padding-left: 14px;
  background-color: #e9e7e2 !important;
  border: 1px solid black;
  font-size: 18px;
}

.simple-text-input::-webkit-input-placeholder {
  background-color: #e9e7e2;
  color: color(textDark);
  font-size: 18px;
}

.simple-text-input:-ms-input-placeholder {
  background-color: #e9e7e2;
  color: color(textDark);
  font-size: 18px;
}

.simple-text-input::placeholder {
  background-color: #e9e7e2;
  color: color(textDark);
  font-size: 18px;
}

.text-input-transparent {
  height: 40px;
  width: 100%;
  padding-left: 14px;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  font-size: 18px;
}

.text-input-transparent::-webkit-input-placeholder {
  background-color: transparent;
  color: black;
  font-size: 18px;
}

.text-input-transparent:-ms-input-placeholder {
  background-color: transparent;
  color: black;
  font-size: 18px;
}

.text-input-transparent::placeholder {
  background-color: transparent;
  color: black;
  font-size: 18px;
}

.text-input-small {
  height: 30px;
  width: 220px;
  background-color: transparent;
  padding-left: 10px;
  border: 1px solid black;
  color: black;
  font-size: 16px;
}

.text-input-small::-webkit-input-placeholder {
  background-color: transparent;
  color: black;
  font-size: 16px;
}

.text-input-small:-ms-input-placeholder {
  background-color: transparent;
  color: black;
  font-size: 16px;
}

.text-input-small::placeholder {
  background-color: transparent;
  color: black;
  font-size: 16px;
}
.standard-form {
  border: 1px solid black;
  width: 480px;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.select-text {
  padding-left: 12px;
}

.button {
  display: block;
  text-align: center;
  text-justify: middle;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
@media (max-width: 420px) {
  .button {
    font-size: 14px;
  }
}
@media (max-width: 340px) {
  .button {
    font-size: 12px;
  }
}
@media (max-width: 310px) {
  .button {
    font-size: 8px;
  }
}
.button-standard-size {
  max-height: 36px;
  max-width: 100%;
  min-width: 90px;
  line-height: 36px;
}
.button-basic {
  border: 1px solid black;
  background-color: transparent;
  transition: 0.3s;
}
.button-basic:disabled {
  border: 1px solid grey;
  cursor: default;
}
.button-primary {
  color: white;
  background-color: #369;
}
.button-success {
  color: black;
  background-color: white;
}
.button-danger {
  color: black;
  background-color: #955;
}

.accordion-image {
  height: 80px;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
  margin-top: 75px;
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  background: rgb(23, 84, 199) !important;
  color: whitesmoke;
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}

.stripe-form {
  /* width: 60vw; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
.stripe-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.stripe-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}
.stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
.stripe-form {
  /* width: 60vw; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
.stripe-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.stripe-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}
.stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}

/*Navbar*/
.nav {
  width: 100%;
  text-align: right;
  height: 70px;
  line-height: 70px;
  font-size: 1.9em;
  background: #e9e7e2;
}
#hamburger {
  position: absolute;
  left: 15px;
  top: 18px;
  display: none;
}
.menu {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}
.menu a,
.menu-show a {
  clear: right;
  color: black;
  font-family: "Josefin Slab";
  margin: 0px 45px 0px 0px;
  display: flex;
  transition: 0.3s;
  text-decoration: none;
}
.menu a:hover,
.menu-show a:hover {
  color: blue;
}
.home-button-icon {
  height: 45px;
  transition: 0.2s;
  border-radius: 50%;
  border: 2px solid white;
}
.home-button-text {
  display: none;
}
.home-button-icon:hover {
  transform: scale(1.2);
  border: 2px solid blue;
}
#home-button-link {
  height: 45px;
  width: 45px;
  margin: 10px 10px 10px 10px;
  border-radius: 50%;
}
#top-bar {
  position: absolute;
  top: 50px;
  left: 70px;
  width: calc(100% - 70px);
  height: 1px;
  background: black;
  z-index: 999;
}

@media only screen and (max-width: 800px) {
  .home-button-text {
    display: block;
  }
  #hamburger {
    display: block;
    cursor: pointer;
  }
  #top-bar {
    width: 0px;
    transition: 0.3s;
  }
  .menu {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #e7e7e3;
    height: 70px;
    display: none;
  }
  .menu-show {
    text-align: center;
    width: 100%;
    /* height: 100%; */
    background: #e7e7e3;
    display: block;
    top: 70px;
    position: absolute;
    z-index: 999;
  }
  .menu-show a {
    display: block;
    height: 70px;
    width: 100%;
    background: #e7e7e3;
    border-bottom: 2px solid black;
  }
  .home-button-icon {
    display: none;
  }
  #home-button-link {
    width: 100%;
    border-radius: 0;
    height: 70px;
    margin: 0px;
  }
}

