.bun-story-text-container {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  z-index: 2;
}
.story-title {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 54px;
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 50px;
  text-shadow: 1px 1px 10px black;
}
.bun-story-text {
  color: white;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  font-size: 24px;
  width: 40%;
  text-shadow: 2px 2px 5px black;
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  border: 1px solid white;
}
.bun-text-index {
  font-size: 10px;
  margin-top: 5px;
  text-align: right;
}
.story-credits-container {
  align-self: center;
  margin-bottom: 50vh;
}
.story-credits {
  transition: 1s;
  color: white !important;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
  border: 1px solid white;
  align-self: center;
  padding: 20px;
  background-color: black;
}
.story-credits-text {
  color: white !important;
  text-align: center;
  margin-bottom: 10px;
  transition: 1s;
}
.story-credits-title {
  transition: 1s;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Josefin Slab", "Source Sans Pro", sans-serif;
}
.credits-text-1 {
  transition-delay: 0.3s;
}
.credits-text-2 {
  transition-delay: 0.5s;
}
.credits-text-3 {
  transition-delay: 0.7s;
}
.credits-text-4 {
  transition-delay: 1s;
}
.transparent-above {
  opacity: 0;
  transform: translateY(-20px);
}
@media screen and (max-width: 700px) {
  .bun-story-text {
    padding: 10px;
    font-size: 14px;
    width: 50%;
  }
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-height: 700px) {
  .story-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .bun-story-text {
    padding: 10px;
    font-size: 12px;
    width: 50%;
  }
}
.transition-1-sec {
  transition: ease-in-out 1s;
}
.transparent {
  opacity: 0;
  pointer-events: none;
}
.text-show {
  opacity: 1;
}
.text-1 {
  margin-top: 10vh;
}
.text-2-class-1 {
  transform: translateX(-50%);
}
.text-4 {
  margin-bottom: 80vh;
}
.text-6 {
  margin-bottom: 80vh;
}
.text-7 {
  margin-bottom: 60vh;
}
.text-8 {
  margin-bottom: 60vh;
}
.text-13 {
  margin-bottom: 30vh;
}
.text-18 {
  margin-bottom: 40vh;
}
.text-19 {
  margin-bottom: 30vh;
}
.text-20 {
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30vh;
}
.text-26 {
  margin-bottom: 40vh;
}
.text-27 {
  transition: 0.3s;
}
.text-27-class-1 {
  transform: skew(10deg, 0deg);
}
.text-104 {
  margin-bottom: 50vh;
}

.text-3,
.text-4,
.text-7,
.text-9,
.text-13,
.text-16,
.text-17,
.text-26,
.text-32,
.text-33,
.text-34,
.text-35,
.text-39,
.text-41,
.text-43,
.text-45,
.text-50,
.text-52,
.text-54,
.text-56,
.text-58,
.text-67,
.text-65,
.text-68,
.text-69,
.text-70,
.text-71,
.text-72,
.text-75,
.text-76,
.text-77,
.text-79,
.text-85,
.text-87,
.text-88,
.text-89,
.text-90,
.text-98,
.text-99,
.text-100 {
  align-self: flex-end;
}

.text-4-class-1,
.text-17-class-1,
.text-32-class-1,
.text-35-class-1,
.text-39-class-1,
.text-41-class-1,
.text-43-class-1,
.text-45-class-1,
.text-50-class-1,
.text-52-class-1,
.text-54-class-1,
.text-56-class-1,
.text-58-class-1,
.text-85-class-1,
.text-87-class-1,
.text-89-class-1 {
  transform: translate(150%);
}

.text-10-class-1,
.text-37-class-1,
.text-40-class-1,
.text-42-class-1,
.text-44-class-1,
.text-46-class-1,
.text-48-class-1,
.text-51-class-1,
.text-53-class-1,
.text-55-class-1,
.text-57-class-1,
.text-59-class-1,
.text-63-class-1,
.text-78-class-1,
.text-82-class-1,
.text-84-class-1,
.text-86-class-1,
.text-92-class-1,
.text-94-class-1 {
  transform: translate(-150%);
}

.text-5,
.text-6,
.text-8,
.text-27,
.text-31,
.text-64,
.text-73,
.text-74,
.text-95,
.text-96,
.text-101 {
  width: 100%;
  align-self: center;
}
.text-64 {
  margin-bottom: 30vh;
  text-align: center;
}

.text-5-class-1,
.text-7-class-1,
.text-95-class-1 {
  transform: skewX(10deg);
}
.text-6-class-1,
.text-96-class-1 {
  transform: skewX(-10deg);
}
