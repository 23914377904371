/*Navbar*/
.nav {
  width: 100%;
  text-align: right;
  height: 70px;
  line-height: 70px;
  font-size: 1.9em;
  background: #e9e7e2;
}
#hamburger {
  position: absolute;
  left: 15px;
  top: 18px;
  display: none;
}
.menu {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}
.menu a,
.menu-show a {
  clear: right;
  color: black;
  font-family: "Josefin Slab";
  margin: 0px 45px 0px 0px;
  display: flex;
  transition: 0.3s;
  text-decoration: none;
}
.menu a:hover,
.menu-show a:hover {
  color: blue;
}
.home-button-icon {
  height: 45px;
  transition: 0.2s;
  border-radius: 50%;
  border: 2px solid white;
}
.home-button-text {
  display: none;
}
.home-button-icon:hover {
  transform: scale(1.2);
  border: 2px solid blue;
}
#home-button-link {
  height: 45px;
  width: 45px;
  margin: 10px 10px 10px 10px;
  border-radius: 50%;
}
#top-bar {
  position: absolute;
  top: 50px;
  left: 70px;
  width: calc(100% - 70px);
  height: 1px;
  background: black;
  z-index: 999;
}

@media only screen and (max-width: 800px) {
  .home-button-text {
    display: block;
  }
  #hamburger {
    display: block;
    cursor: pointer;
  }
  #top-bar {
    width: 0px;
    transition: 0.3s;
  }
  .menu {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #e7e7e3;
    height: 70px;
    display: none;
  }
  .menu-show {
    text-align: center;
    width: 100%;
    /* height: 100%; */
    background: #e7e7e3;
    display: block;
    top: 70px;
    position: absolute;
    z-index: 999;
  }
  .menu-show a {
    display: block;
    height: 70px;
    width: 100%;
    background: #e7e7e3;
    border-bottom: 2px solid black;
  }
  .home-button-icon {
    display: none;
  }
  #home-button-link {
    width: 100%;
    border-radius: 0;
    height: 70px;
    margin: 0px;
  }
}
