.element-slider-show {
  opacity: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
  left: 0;
  transition: 2.3s;
  max-height: 100%;
  overflow: hidden;
}
.element-slider-hidden-top {
  opacity: 0;
  position: absolute;
  top: 40%;
  right: 0;
  transition: 0;
  max-height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.element-slider-hidden-left {
  opacity: 0;
  position: absolute;
  left: 200;
  transition: 0.3s;
}
.slide-text {
  max-height: 100%;
  overflow: hidden;
  font-size: 3.5em;
  color: white;
  text-shadow: 3px 3px 3px black;
}
.slide-button {
  width: 500px;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
  border: 5px solid white;
}
.slide-button:hover {
  transition: 0.3s;
  border-radius: 15px;
  /* background: rgba(200, 200, 200, 0.5); */
}

@media only screen and (max-width: 500px) {
  .element-slider-show {
    top: 40%;
  }
  .element-slider-hidden-top {
    top: 25%;
  }
  .slide-text {
    font-size: 2em;
  }
  .slide-button {
    width: 300px;
    max-width: 80%;
  }
}
